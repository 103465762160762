<template>
  <div class="stamp_properties">
    <div class="properties_header">Stamp Properties</div>
    <table>
      <tr>
        <td class="prompt_right">Value:</td>
        <td class="data">
          <input v-model="stamp.value"/>
        </td>
      </tr>
      <tr>
        <td class="prompt_right">Color:</td>
        <td class="data">
          <textarea style="height:4em;width:100%;" v-model="stamp.color"/>
        </td>
      </tr>
      <tr>
        <td class="prompt_right">Scott Num:</td>
        <td class="data">
          <input v-model="stamp.snum"/>
        </td>
      </tr>
      <tr>
        <td colspan=2 style="text-align:center;font-size:14px;" class="properties_header">Style</td>
      </tr>
      <tr>
        <td class="prompt_right">Font size:</td>
        <td class="data">
          <input v-model="stamp.font_size"/>px
        </td>
      </tr>

      <tr>
        <td class="prompt_right">Height:</td>
        <td class="data">
          <input v-model="stamp.height"/>inches
        </td>
      </tr>
      <tr>
        <td class="prompt_right">Width:</td>
        <td class="data">
          <input v-model="stamp.width"/>inches
        </td>
      </tr>
      <tr>
        <td class="prompt_right">Horizontal margin:</td>
        <td class="data">
          <input v-model="stamp.horizontal_margin"/>px
        </td>
      </tr>
      <tr>
        <td class="prompt_right">Vertical margin:</td>
        <td class="data">
          <input v-model="stamp.vertical_margin"/>px
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'SmStampProperties',
  computed: {
    ...mapState(['stamps','propertiesItemUUID']),
    stamp(){
      return this.stamps[this.propertiesItemUUID]
    }
  },
  methods: {
    ...mapMutations(['updateStamp'])
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.stamp_properties {
}
.properties_header {
  font-weight     : bold;
  text-decoration : underline;
  margin-bottom   : 16px;
}
</style>
