<template>
  <div class="group" :class="{ 'highlighted': groupUUID === propertiesItemUUID }" @click.stop="callSetProperties('SmGroupProperties',groupUUID)" :style="computedStyles">
    <SmSubHeader v-for="subheaderUUID in group.subheaders" :subheaderUUID="subheaderUUID" :key="subheaderUUID"/>
    <div>
      <SmStamp v-for="stampUUID in group.stamps" :stampUUID="stampUUID" :key="stampUUID"/>
    </div>
    <RemoveBtn @click.stop="removeGroup(groupUUID)"/>
    <CloneBtn @click.stop="cloneGroup(groupUUID)"/>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import SmStamp     from './SmStamp'
import SmSubHeader from './SmSubHeader'
import RemoveBtn   from './RemoveBtn'
import CloneBtn    from './CloneBtn'

export default {
  name: 'SmGroup',
  components: {
    SmStamp,
    SmSubHeader,
    RemoveBtn,
    CloneBtn,
  },
  computed: {
    ...mapState(['groups','stamps','propertiesItemUUID']),
    group(){
      return this.groups[this.groupUUID]
    },
    computedStyles(){
      console.log("vert"+this.group.vertical_align);
      for( var idx in this.group.stamps ){
        console.log("id---x:"+idx);
        var stamp = this.stamps[this.group.stamps[idx]];
        stamp.vertical_align = this.group.vertical_align;
      }
      return {
        '--group-vertical-align' : (this.group.vertical_align || 'middle')
      }
    }
  },
  methods: {
    ...mapMutations(['addStamp','removeGroup','setPropertiesComponent','cloneGroup']),
    callSetProperties( componentName, itemUUID ){
      this.setPropertiesComponent( {componentName, itemUUID} )
    }
  },
  props: {
    groupUUID: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media print {
  .group {
    box-sizing     : border-box;
    padding        : 10px;
    border         : none;
    vertical-align : middle;
    margin-top     : auto;
    margin-bottom  : auto;
    position       : relative;
    min-height     : 60px;
    vertical-align : var(--group-vertical-align);
  }
}

@media screen {
  .group {
    box-sizing     : border-box;
    padding        : 10px;
    border         : solid 2px #F2F2F2; 
    vertical-align : middle;
    margin-top     : auto;
    margin-bottom  : auto;
    position       : relative;
    min-height     : 60px;
    vertical-align : var(--group-vertical-align);
  }
}
</style>

