<template>
  <div class="group_properties">
    <div class="properties_header">Group Properties</div>
    <table>
      <tr>
        <td colspan=2>
          <button @click.stop="addSubHeader(propertiesItemUUID)">Add SubHeader</button>
        </td>
      </tr>

      <tr>
        <td colspan=2>
          <button @click.stop="addStamp(propertiesItemUUID)">Add stamp</button>
        </td>
      </tr>
      <tr>
        <td class="prompt_right">Vertical alignment:</td>
        <td class="data">
          <select v-model="group.vertical_align">
            <option value="top">Top</option>
            <option value="middle">Middle</option>
            <option value="bottom">Bottom</option>
          </select>
        </td>
      </tr>

    </table>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'SmGroupProperties',
  computed: {
    ...mapState(['groups','propertiesItemUUID']),
    group(){
      return this.groups[this.propertiesItemUUID]
    }
  },
  methods: {
    ...mapMutations(['addStamp','addSubHeader'])
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.group_properties {
}
.properties_header {
  font-weight     : bold;
  text-decoration : underline;
  margin-bottom   : 15px;
}
.prompt_right {
  text-align  : right;
}
</style>
