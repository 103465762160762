// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import { createApp } from 'vue'
import App from './App.vue'
import store from './store.js';
import './assets/global.css';

const app = createApp(App);

app.use(store);
app.mount('#app');

////Vue.config.productionTip = false
//
///* eslint-disable no-new */
//new Vue({
//  el: '#app',
//  components: { App },
//  template: '<App/>'
//})
