<template>
  <div class="sheet_properties">
    <div class="properties_header">Sheet Properties</div>
    <table>
      <tr>
        <td class="prompt_right">Country:</td>
        <td class="data">
          <input v-model="sheet.country"/>
        </td>
      </tr>
      <tr><td colspan=2 style="font-size:14px;" class="properties_header">Style</td></tr>
      <tr>
        <td class="prompt_right">Font size:</td>
        <td style="text-align:left;">
          <input v-model="sheet.font_size"/>px
        </td>
      </tr>
      <tr>
        <td class="prompt_right">Country letter spacing:</td>
        <td class="data">
          <input v-model="sheet.country_letter_spacing"/>px
        </td>
      </tr>

      <tr>
        <td colspan=2>
          <button @click.stop="addGroup(propertiesItemUUID)">Add Group</button>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapState,mapMutations } from 'vuex'
export default {
  name: 'SmSheetProperties',
  computed: {
    ...mapState(['sheets','propertiesItemUUID']),
    sheet(){
      return this.sheets[this.propertiesItemUUID]
    }
  },
  methods: {
    ...mapMutations(['addGroup'])
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.sheet_properties {
}
.properties_header {
  font-weight     : bold;
  text-decoration : underline;
  margin-bottom   : 15px;
}
.prompt_right {
  text-align  : right;
}
</style>
