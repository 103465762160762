<template>
  <div class="subheader" :class="{ 'highlighted': subheaderUUID === propertiesItemUUID }" @click.stop="callSetProperties('SmSubHeaderProperties',subheaderUUID)" :style="computedStyles">
    <div>{{subheader.text}}</div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'SmSubHeader',
  components: {
  },
  computed: {
    ...mapState(['subheaders','propertiesItemUUID']),
    subheader(){
      return this.subheaders[this.subheaderUUID]
    },
    computedStyles(){
      return {
        '--sub-header-font-weight'      : ( this.subheader.bold_flag ) ? 'bold' : 'normal',
        '--sub-header-font-style'       : ( this.subheader.italic_flag ) ? 'italic' : 'normal',
        '--sub-header-font-size'        : this.subheader.font_size + 'px',
        '--sub-header-text-decoration'  : ( this.subheader.underline_flag ) ? 'underline' : 'none'
      }
    }
  },
  methods: {
    ...mapMutations(['removeSubHeader','setPropertiesComponent']),
    callSetProperties( componentName, itemUUID ){
      this.setPropertiesComponent( {componentName, itemUUID} )
    }
  },
  props: {
    subheaderUUID: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.subheader {
  font-size       : var(--sub-header-font-size);
  font-weight     : bold;
  font-family     : 'Pala';
  border          : solid 1px white;
  cursor          : pointer;
  position        : relative;
  width           : 50%;
  margin-left     : auto;
  margin-right    : auto;
  font-weight     : var(--sub-header-font-weight);
  font-style      : var(--sub-header-font-style);
  text-decoration : var(--sub-header-text-decoration);
  min-height      : 17px;
}
</style>
