<template>
  <div class="sheet" :class="{ 'highlighted': sheetUUID === propertiesItemUUID }" @click.stop="callSetProperties('SmSheetProperties',sheetUUID)" :style="computedStyles">
    <span class="country">{{sheet.country}}</span>
  <!--
    <SmControls @add-group="handleAddGroup"/>
    -->
    <SmGroup v-for="groupUUID in sheet.groups" :groupUUID="groupUUID" :key="groupUUID"/>
    <img class="background" src="../assets/sheet_background.png"/>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
//import SmControls from './SmControls'
import SmGroup from './SmGroup'

export default {
  name: 'SmSheet',
  components: {
//    SmControls,
    SmGroup
  },
  computed: {
    ...mapState(['sheets','propertiesItemUUID']),
    sheet(){
      return this.sheets[this.sheetUUID]
    },
    computedStyles(){
      return( { '--country-letter-spacing' : this.sheet.country_letter_spacing + 'px',
                '--sheet-font-size' : this.sheet.font_size + 'px',
              } );
    }
  },
  methods: {
    ...mapMutations(['addGroup','removeGroup','setPropertiesComponent']),
    callSetProperties( componentName, itemUUID ){
      this.setPropertiesComponent( {componentName, itemUUID} )
    }
  },
  props: {
    sheetUUID: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media screen {
  .sheet {
    height : 10.377in;
    width  : 8in;
    padding : .5in .5in;
    box-sizing : border-box;
    background-repeat : no-repeat;
    background-size  : 8in 10.377in;
    border: solid 2px white;
    position: relative;
  }
}
@media print {
  .sheet {
    height : 10.377in;
    width  : 8in;
    padding : .5in .5in;
    box-sizing : border-box;
    background-repeat : no-repeat;
    background-size  : 8in 10.377in;
    border: solid 2px white;
    position: relative;
  }
}

.background {
  height: 100%;
  width: 100%;
  position: absolute;
  top:0px;
  left:0px;
  z-index:-1;
}
.country {
  font-weight: bold;
  font-size: var(--sheet-font-size);
  margin-top: .5625in;
  font-family : 'Palab';
  letter-spacing : var(--country-letter-spacing);
}

</style>
