<template>
  <img src="@/assets/remove.png" alt="Remove" class="remove_btn no-print" @click.stop="handleClick" title="Remove"/>
</template>

<script>
export default {
  name: 'RemoveButton',
  methods: {
    handleClick () {
      // Emit the click event to the parent
      this.$emit('btn-remove')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.remove_btn {
  position         : absolute;
  top              : 0px;
  right            : 0px;
  height           : 30px;
  width            : 30px;
  cursor           : pointer;
}

</style>
