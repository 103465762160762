import { createStore } from 'vuex';

//// Helper functions
// eslint-disable-next-line no-unused-vars
function uuid(type){
  return 'stamp_maker_' + type + '_' + new Date().getTime() + Math.floor(Math.random()*1000);
}
// eslint-disable-next-line no-unused-vars
function removeItemAll(arr, value) {
  var i = 0;
  while (i < arr.length) {
    if (arr[i] === value) {
      arr.splice(i, 1);
    } else {
      ++i;
    }
  }
  return arr;
}

// eslint-disable-next-line no-unused-vars
function cloneBase( state, itemUUID, type ){
  var table          = state[type + 's'];
  var newItemUUID    = uuid(type);
  while( table[newItemUUID] !== undefined ){
    newItemUUID = uuid(type);
  }
  var newItem        = Object.assign({},table[itemUUID]);
  table[newItemUUID] = newItem;
  return( newItemUUID );
}



const store = createStore({
  state: {
    sheets: {
      "one" : { country: 'UNITED STATES',
                groups: ['g1','g2'],
                font_size : '15'
              }
    },
    groups: {
      'g1' : { stamps: ['s1','s2'],
               subheaders: ['sh1']
             },
      'g2' : { year: "1963",
               description: "DOG POOP SCOOP STAMPS",
               stamps: ['s3','s4'],
               subheaders: ['sh2','sh3']
             }
    },
    stamps: {
      's1' : { value: "10",
               color: "Olivander",
               snum: "111",
               horizontal_margin: "15",
               vertical_margin: "15",
               height: "1",
               width: "1.5",
               font_size: "13"
             },
      's2' : { value: "11",
               color: "Green",
               snum: "172",
               horizontal_margin: "15",
               vertical_margin: "15",
               height: "1",
               width: "1.5",
               font_size: "13"
             },
      's3' : { value: "12",
               color: "Forest",
               snum: "132",
               horizontal_margin: "15",
               vertical_margin: "15",
               height: "1",
               width: "1.5",
               font_size: "13"
             },
      's4' : { value: "13",
               color: "Puece",
               snum: "112",
               horizontal_margin: "15",
               vertical_margin: "15",
               height: "1",
               width: "1.5",
               font_size: "13"
             }
    },
    subheaders: {
      'sh1' : { text: 'Sub header 1',
                font_size: '14'
              },
      'sh2' : { text: 'Sub header 2',
                font_size: '14'
              },
      'sh3' : { text: 'Sub header 3',
                font_size: '14'
              }
    },
    settings: {
      defaults : {
        sheet : { font_size : "15",
                },
        sub_header : 
                {
                  font_size : "14",
                },
        stamp : { value : "10",
                  color : "Blue",
                  snum  : "-",
                  height : "1",
                  width  : "1.5",
                  font_size : "13",
                  horizontal_margin: "15",
                  vertical_margin: "15",
                  vertical_alignment: "middle"
                }
      }
    },
    propertiesComponentName: null,
    propertiesItemUUID: null,
    showSettingsFlag: false
  },
  mutations: {
    //// Sheet mutations

    //// Group mutations
    addGroup(state, sheetUUID){
      var sheet = state.sheets[sheetUUID];
      var groupUUID = uuid('group');
      state.groups[groupUUID] = { subheaders : [], stamps : [] };
      sheet.groups.push( groupUUID );
    },
    removeGroup(state, groupUUID){
      delete state.groups[groupUUID];
      for( var skey in state.sheets ){
        var sheet = state.sheets[skey];
        removeItemAll( sheet.groups, groupUUID );
      }
    },
    cloneGroup(state, groupUUID){
      var newGroupUUID = uuid('group');
      var newGroup = { subheaders : [], stamps : [] };
      var oldGroup = state.groups[groupUUID];
      // We have to clone the subheaders
      for( var idx in oldGroup.subheaders ){
        var hkey = oldGroup.subheaders[idx];
        newGroup.subheaders.push( cloneBase( state, hkey, 'subheader' ) )
      }
      // We have to clone the stamps
      for( idx in oldGroup.stamps ){
        var skey = oldGroup.stamps[idx];

        newGroup.stamps.push( cloneBase( state, skey, 'stamp' ) )
      }

      state.groups[newGroupUUID] = newGroup;

      for( var pkey in state.sheets ){
        var sheet = state.sheets[pkey];
        var origIdx = sheet.groups.indexOf(groupUUID);
        if( origIdx != -1 ){
          sheet.groups.splice(origIdx+1,0,newGroupUUID);
        }
      }
    },

    //// SubHeader mutations
    addSubHeader(state, groupUUID){
      var group = state.groups[groupUUID];
      var subheaderUUID = uuid('subheader');
      state.subheaders[subheaderUUID] = { text : 'Click to set text' };
      group.subheaders.push( subheaderUUID );
    },

    removeSubHeader(state, subheaderUUID){
      if( subheaderUUID == state.propertiesItemUUID ){
        state.propertiesItemUUID = undefined;
        state.propertiesComponentName = undefined;
      }
      delete state.subheaders[subheaderUUID];
      for( var gkey in state.groups ){
        var group = state.groups[gkey];
        removeItemAll( group.subheaders, subheaderUUID );
      }
    },


    //// Stamp mutations
    addStamp(state, groupUUID){
      var group = state.groups[groupUUID];
      var stampUUID = uuid('stamp');
      state.stamps[stampUUID] = Object.assign({},state.settings.defaults.stamp);
      group.stamps.push( stampUUID );
    },
    removeStamp(state, stampUUID){
      if( state.propertiesItemUUID == stampUUID ){
        state.propertiesComponentName = undefined;
        state.propertiesItemUUID = undefined;
      }
      delete state.stamps[stampUUID];
      for( var gkey in state.groups ){
        var group = state.groups[gkey];
        removeItemAll( group.stamps, stampUUID );
      }
    },
    cloneStamp(state, stampUUID){
      var newStampUUID = uuid('stamp');
      state.stamps[newStampUUID] = Object.assign({},state.stamps[stampUUID]);
      for( var gkey in state.groups ){
        var group = state.groups[gkey];
        var origIdx = group.stamps.indexOf(stampUUID);
        if( origIdx != -1 ){
          group.stamps.splice(origIdx,0,newStampUUID);
        }
      }
    },


    // General mutations
    setPropertiesComponent(state, { componentName, itemUUID }){
      // Can we highlight the calling component here?
      state.showSettingsFlag = false;
      state.propertiesComponentName = componentName;
      state.propertiesItemUUID = itemUUID;
    },
    updateStoreData(state, payload){
      Object.assign(state, payload.state);
    },
    showSettings(state){
      state.showSettingsFlag = true;
      state.propertiesComponentName = null;
      state.propertiesItemUUID = null;
    },
    clearData(state){
      state.sheets['one'].groups = [];
      state.stamps     = {};
      state.subheaders = {};
      state.groups     = {};
    }
  },
  actions: {
    saveStoreDataToFile(storeData) {
      const storeDataJSON = JSON.stringify(storeData);
      const blob = new Blob([storeDataJSON], { type: 'application/json' });
      const fileName = `store_data_${Date.now()}.json`;
    
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(blob);
      a.download = fileName;
      a.style.display = 'none';
    
      document.body.appendChild(a);
      a.click();
    
      document.body.removeChild(a);
    },
    loadStoreDataFromFile(state, callback) {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'application/json';
    
      input.addEventListener('change', (event) => {
        const file = event.target.files[0];
    
        if (file) {
          const reader = new FileReader();
          
          reader.onload = (e) => {
            try {
              const storeData = JSON.parse(e.target.result);
              callback(storeData);
            } catch (error) {
              console.error('Error parsing JSON:', error);
            }
          };
    
          reader.readAsText(file);
        }
      });
      input.click();
    }
  }
});

export default store;
