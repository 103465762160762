<template>
  <div class="settings">
    <h2>Global Settings</h2>

    <h3>Defaults (Used when creating new)</h3>
    <table style="width:100%;">
      <tr><td colspan=2 class="header">Stamp</td></tr>
      <tr>
        <td class="prompt_right">Value:</td>
        <td>
          <input v-model="stamp.value"/>
        </td>
      </tr>
      <tr>
        <td class="prompt_right">Color:</td>
        <td>
          <input v-model="stamp.color"/>
        </td>
      </tr>
      <tr>
        <td class="prompt_right">Snum::</td>
        <td>
          <input v-model="stamp.snum"/>
        </td>
      </tr>
      <tr>
        <td class="prompt_right">Value:</td>
        <td>
          <input v-model="stamp.value"/>
        </td>
      </tr>
      <tr>
        <td class="prompt_right">Height:</td>
        <td>
          <input v-model="stamp.height"/>
        </td>
      </tr>
      <tr>
        <td class="prompt_right">Width:</td>
        <td>
          <input v-model="stamp.width"/>
        </td>
      </tr>
      <tr>
        <td class="prompt_right">Horizontal margin:</td>
        <td>
          <input v-model="stamp.horizontal_margin"/>
        </td>
      </tr>
      <tr>
        <td class="prompt_right">Vertical margin:</td>
        <td>
          <input v-model="stamp.vertical_margin"/>
        </td>
      </tr>

    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'SmSettings',
  components: {
  },
  computed: {
    ...mapState(['settings']),
    stamp(){
      return this.settings.defaults.stamp
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.settings {
  background-color : #EE8833;
  color            : white;
  padding          : 10px;
  border           : solid 1px black;
}
.header {
  text-align      : center;
  font-size       : 16px;
  font-weight     : bold;
  text-decoration : underline;
}
</style>
