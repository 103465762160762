<template>
  <div class="stamp" :class="{ 'highlighted': stampUUID === propertiesItemUUID }" @click.stop="callSetProperties('SmStampProperties',stampUUID)" :style="computedStyles">
    <div class="wrapper">
      <div class="detail value">{{stamp.value}}</div>
      <div class="detail color">{{stamp.color}}</div>
      <div class="detail snum"><span STYLE="text-transform:capitalize">Scott </span>{{stamp.snum}}</div>
    </div>
    <RemoveBtn @click.stop="removeStamp(stampUUID)"/>
    <CloneBtn  @click.stop="cloneStamp(stampUUID)"/>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import RemoveBtn from './RemoveBtn'
import CloneBtn from './CloneBtn'
export default {
  name: 'SmStamp',
  components: {
    RemoveBtn,
    CloneBtn
  },
  computed: {
    ...mapState(['stamps','propertiesItemUUID']),
    stamp(){
      return this.stamps[this.stampUUID]
    },
    computedStyles(){
      return {
        '--stamp-height' : (this.stamp.height||1) + 'in',
        '--stamp-width' : (this.stamp.width||1.5) + 'in',
        '--stamp-vertical-margin' : this.stamp.vertical_margin + 'px',
        '--stamp-horizontal-margin' : this.stamp.horizontal_margin + 'px',
        '--stamp-font-size' : this.stamp.font_size + 'px'
      };
    }
  },
  methods: {
    ...mapMutations(['removeStamp','setPropertiesComponent','cloneStamp']),
    callSetProperties( componentName, itemUUID ){
      this.setPropertiesComponent( {componentName, itemUUID} )
    },
  },
  props: {
    stampUUID: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.stamp {
  width          : var(--stamp-width);
  height         : var(--stamp-height);
  border-style   : dashed;
  border-width   : 1px;
  vertical-align : middle;
  margin-top     : var(--stamp-vertical-margin);
  margin-bottom  : var(--stamp-vertical-margin);
  margin-left    : var(--stamp-horizontal-margin);
  margin-right   : var(--stamp-horizontal-margin);
  display        : inline-block;
  position       : relative;
  cursor         : pointer;
  font-family    : "Times New Roman";
}

.wrapper {
  display         : flex;
  flex-direction  : column;
  align-items     : center;
  justify-content : center;
  height          : 100%;
}
.detail {
  text-transform : uppercase;
  font-size      : var(--stamp-font-size);
}
.value {
  color     : black;
}
.color {
  color     : black;
  white-space : pre-wrap;
}
.snum {
  font-style  : italic;
  padding-top : 5px;
  color       : black;
}

</style>
